"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var CCColors = {
  ChessBoard: {
    Light: "#edd899",
    Dark: "#d1ab41",
    StartColor: "rgb(120, 113, 64)",
    EndColor: "rgb(196, 181, 57)",
    GreenLightColor: "#EBECD0",
    GreenDarkColor: "#739552",
    GreenStartColor: "#F6EC77",
    GreenEndColor: "#B9C93B",
    BlueLightColor: "#DFDFDF",
    BlueDarkColor: "#6F84B4",
    BlueStartColor: "#9F9FFD",
    BlueEndColor: "#9F9FFD"
  },
  Progress: {
    Bar: "#D1AB41"
  }
};
var _default = exports["default"] = CCColors;